import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/Layout/Layout"
import styled, { keyframes } from "styled-components"
import Icon from "../images/Icon.svg"
import Button from "../components/Button/Button"
import { faRoute, faPaintBrush, faLaptopCode, faSearchPlus, faTools, faChartLine } from "@fortawesome/free-solid-svg-icons"
import IconTile from "../components/IconTile/IconTile"
import { getImage } from "gatsby-plugin-image"
import Testimonial from "../components/Testimonial/Testimonial"
import ContactCTA from "../components/ContactCTA/ContactCTA"
import { Helmet } from "react-helmet"

const flicker = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  10.1% {
    opacity: 1;
  }
  10.2% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  20.1% {
    opacity: 1;
  }
  20.6% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  30.1% {
    opacity: 1;
  }
  30.5% {
    opacity: 1;
  }
  30.6% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  45.1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  55.1% {
    opacity: 0;
  }
  57% {
    opacity: 0;
  }
  57.1% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  60.1% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  65.1% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  75.1% {
    opacity: 0;
  }
  77% {
    opacity: 0;
  }
  77.1% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  85.1% {
    opacity: 0;
  }
  86% {
    opacity: 0;
  }
  86.1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`

const IconFlicker = styled.img`
	-webkit-animation: ${flicker} 2.5s linear both;
	animation: ${flicker} 2.5s linear both;
  flex-basis: 300px;
`

const Container = styled.div`
  width: min(90%, 1200px);
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-6) var(--space-7);
  align-items: center;
  justify-content: center;
`

const TextSection = styled.div`
  flex-basis: 400px;
  flex-grow: 1;

  h1 {
    margin-top: 0;
  }
`

const Divider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;

  svg {
      position: relative;
      display: block;
      width: calc(100% + 1.3px);
      height: 100px;
  }

  .shape-fill {
      fill: #1F3236;
  }
`

const TileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-6);
  padding: var(--space-6) 0;
`

const Intro = styled.div`
  max-width: 1000px;
  margin: auto;
  text-align: center;
`

function Home({ data }) {
  const koIcon = getImage(data.koIcon)
  const contactImage = getImage(data.contactImage)

  return (
    <Layout>
      <Helmet>
        <meta name="description" content="Outstanding websites that deliver results. Help your business achieve its goals with a website that delivers results and makes the most of your online opportunities." />
      
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://goddard.digital/" />
        <meta property="og:title" content="Goddard Digital - Websites that deliver results" />
        <meta property="og:description" content="Outstanding websites that deliver results. Help your business achieve its goals with a website that delivers results and makes the most of your online opportunities." />
        <meta property="og:image" content="https://goddard.digital/static/web-devices-f440bf3461d2b3db58cdbee68a4498a0.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://goddard.digital/" />
        <meta name="twitter:title" content="Goddard Digital - Websites that deliver results" />
        <meta name="twitter:description" content="Outstanding websites that deliver results. Help your business achieve its goals with a website that delivers results and makes the most of your online opportunities." />
        <meta name="twitter:image" content="https://goddard.digital/static/web-devices-f440bf3461d2b3db58cdbee68a4498a0.png" />
      
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Goddard Digital",
              "image": "https://gideongoddard.co.uk/static/1454275122a69e490bddaa66430a4f88/a7dc7/logo.png",
              "@id": "",
              "url": "https://goddard.digital/",
              "telephone": "07540937646",
              "priceRange": "£300 - £10000",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "32 Garfield Park, Great Glen",
                "addressLocality": "Leicester",
                "postalCode": "LE8 9JY",
                "addressCountry": "GB"
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday"
                ],
                "opens": "09:00",
                "closes": "21:00"
              } 
            }
          `}
        </script>
      </Helmet>
      <section className="theme-one">
        <Container>
          <IconFlicker src={Icon} alt="Goddard Digital brand icon" width="300px" />
          <TextSection>
            <h1>Outstanding websites that deliver results.</h1>
            <p>Help your business achieve its goals with a website that delivers results and makes the most of your online opportunities.</p>
            <Button link={true} linkTo="/contact/">Let's get started</Button>
          </TextSection>
        </Container>
      </section>
      <section style={{position: 'relative', backgroundColor: 'var(--secondary-800)'}}>
        <Divider>
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
          </svg>
        </Divider>
        <div style={{width: 'min(90%, 1400px)', margin: 'auto', paddingTop: 'var(--space-5)'}}>
          <Intro>
            <h2>How do we make your website deliver?</h2>
            <p>There's a huge difference between creating a website and creating a website that'll actually deliver results for your business. My industry experience gives a breadth and depth of knowledge and expertise to be that difference.</p>
          </Intro>
          <TileContainer>
            <IconTile icon={faRoute}>
                <h3>Digital Strategy</h3>
                <p>No project should start without a clear understanding of what it's supposed to achieve. We'll work together to ensure your digital activity is perfectly aligned with your business goals.</p>
            </IconTile>
            <IconTile icon={faPaintBrush}>
                <h3>Web Design</h3>
                <p>Great design means more than just looking good – it’s finding the sweet spot between aesthetics, business goals and user experience. It means designing what’ll deliver results.</p>
            </IconTile>
            <IconTile icon={faLaptopCode}>
                <h3>Web Development</h3>
                <p>If you want your website to outperform your competition, it should be lightning-fast, responsive and future-proof whilst looking great and being easy for your customers or clients to use.</p>
            </IconTile>
            <IconTile icon={faSearchPlus}>
                <h3>Search Engine Optimisation</h3>
                <p>When looking to reach an online audience, search engine optimisation (SEO) is a key factor. I consider this at every step of a website project to ensure technical and content excellence.</p>
            </IconTile>
            <IconTile icon={faTools}>
                <h3>Website Testing</h3>
                <p>I take a meticulous approach to testing every aspect of a website, using multiple approaches and tools so that nothing is left to chance in ensuring that quality is of the highest standard.</p>
            </IconTile>
            <IconTile icon={faChartLine}>
                <h3>Analysis & Improvement</h3>
                <p>It's important to make the most of your investment. I offer packages to maintain your site, analyse how it's performing and recommend incremental improvements to get better results.</p>
            </IconTile>
          </TileContainer>
        </div>
      </section>
      <section>
        <Testimonial image={koIcon} name="Emily Coombes" business="Kibworth Ostepaths & Pilates">
          <p>Gideon is amazing! His services and knowledge have helped my business grow really well. Gideon is patient, kind, helpful and incredibly talented. I cannot recommend him highly enough!</p>
        </Testimonial>
      </section>
      <section>
        <ContactCTA image={contactImage} alt="Web devices illustration">
          <p><strong style={{color: 'var(--secondary-900)'}}>Goddard Digital</strong> is run by me, Gideon. I'm a Web Developer & Digital Consultant who helps businesses succeed online by creating websites that convert visits into enquiries and sales. Let's have a conversation about how I can help your business.</p>
          <Button link={true} linkTo="/contact/">Let's get started</Button>
        </ContactCTA>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    koIcon: file(relativePath: {eq: "kibworth-osteopaths-icon.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 100
          placeholder: BLURRED
        )
      }
    }
    contactImage: file(relativePath: {eq: "web-devices.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
        )
      }
    }
  }
`

export default Home